.children-item {
    position: relative;
    padding: 9px 9px;
}

.children-image {
    box-sizing: border-box;
    display:inline-block;
    position: absolute;
    width: 40px;
    height: 40px;
    right: 87%;
}

.children-name{
    position: relative;
    font-family: 'Roboto';
    display:inline-block;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 14px;
    max-width: 50%;
    color: #FFFFFF;
    left: 13%;
    vertical-align:middle;
    cursor: pointer;
}
.container-childrens {
    display:inline-block;
}



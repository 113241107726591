.Category {
  display: flex;
  max-width: 428px;
  margin: auto;
  min-height: 100vh;
	background: url(/public/images/category-header.png) right top no-repeat;
  }
  .container {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
    top: 16%;
}
.a.categoty-item.categoty-item_screenshot {
  top: 20%;
}
.directly_button {
  position: absolute;
  left: 5.07%;
  right: 5.07%;
  top: 89.16%;
  bottom: 4.93%;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  height: 48px;
  background: #4482F8;
  border: 1px solid #558CF3;
  border-radius: 12px;
  z-index: 5;
  font-family: 'Cambay';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 45px;
  text-align: center;
  color: #FFFFFF;
}
.pick_list {
  position: absolute;
  top: 3.0%;
  left: 7%;
  width: 252px;
  height: 29px;
  font-family: 'Cambay';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #FFFFFF;
  z-index: 1000;
}
.Union {
  position: absolute;
  top: 5.5%;
  left: 1%;
}
@media screen and (max-height: 768px) {
  .directly_button {
    margin-top: 20%;
  }
  .Union {
    margin-top: 1%;
  }
}
@media screen and (max-height: 700px) {
  .directly_button {
    margin-top: 30%;
  }
  .container {
    margin-top: 5%;
  }
  .Union {
    margin-top: 0.7%;
  }
}
@media screen and (max-height: 650px) {
  .directly_button {
    margin-top: 50%;
  }
  .container {
    margin-top: 5%;
  }
  .Union {
    margin-top: 1.5%;
  }
}
@media screen and (max-height: 600px) {
	.help_button_onb {
	  margin-top: 60%;
	}
  }
  @media screen and (max-width: 344px) {
    .category-item img {
      width: 135px;
    }
    }

.medicine-item {
    position: relative;
    padding: 9px 9px;
}
.medicine-image {
    box-sizing: border-box;
    display:inline-block;
    position: absolute;
    width: 40px;
    height: 40px;
    right: 87%;
}
.medicine-name{
    position: relative;
    font-family: 'Roboto';
    display:inline-block;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 14px;
    max-width: 50%;
    color: #FFFFFF;
    left: 14.5%;
    vertical-align:middle;
    cursor: pointer;
}
.container-medicine {
    display:inline-block;

}
.donate_button_medicine {
    position: absolute;
    background: #FFFFFF;
    font-family: 'Roboto';
    border: 1px solid #558CF3;
    border-radius: 8px;
    left: 65%;
    width: 120px;
    height: 36px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 36px;
    text-align:center;
    color: #558CF3;
    text-decoration: none;
    align-items: center;
    justify-content: center;
}


.fonds {
  display: flex;
  max-width: 428px;
  margin: auto;
  min-height: 100vh;
	background: url(/public/images/category-header.png) right top no-repeat;
  }
  .fond {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
    top:16%;
}
.pick_list1 {
    position: absolute;
    top: 1em;
    left: 7%;
    width: 252px;
    height: 29px;
    font-family: 'Cambay';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 33px;
    color: #FFFFFF;
    z-index: 1000;
  }
  .Union1 {
    position: absolute;
    top: 2.1%;
    left: 1%;
    z-index: 1000;
  }
  @media screen and (max-height: 700px) {
    .fond {
      top:20%;
  }
    }
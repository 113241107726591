.army {
  display: flex;
  max-width: 428px;
  margin: auto;
  min-height: 100vh;
	background: url(/public/images/arm.png) right top no-repeat;
  }
  .armys {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
    width: 100%;
    top:16%;
}
.container-armys {
  width: 100%;
}
.pick_list {
    position: absolute;
    top: 2.5%;
    left: 7%;
    width: 252px;
    height: 29px;
    font-family: 'Cambay';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #FFFFFF;
    z-index: 1000;
  }
  .Union {
    position: absolute;
    top: 5.5%;
    left: 1%;
    z-index: 1000;
  }
  @media screen and (max-height: 700px) {
    .armys {
      top:20%;
  }
    }
.modal {
    position: fixed;
    height: 115%;
    width: 110%;
    left:-34px;
    top:-90px;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.84);
    animation-name: appear;
    animation-duration: 300ms;
  }
  
  .modal-dialog {
    width: 286px;
    height: 460px;
    left: 12%;
    padding: 9px 9px;
    background: white;
    position: fixed;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: slide-in;
    animation-duration: 0.5s;
  }
  
  .modal-header,
  .modal-footer {
    align-items: center;
    padding: 1rem;
  }
  
  .modal-title {
    font-family: 'Cambay';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #000000;
  }
  .fond-prew {
    position: relative;
    left:100px;
    width: 44px;
    height: 45px;
    
  }
  .modal-link {
    position:absolute;
    cursor: pointer;
    text-decoration: none;
    bottom: 1%;
    right: 37%;
    font-family: 'Cambay';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 29px;
    color: #6EA0FF;
  }
  .modal-close {
    cursor: pointer;
    position:fixed;
    top: 40px;
    right:40px;
    z-index: 9999;
  }
  .modal-skip {
    position:absolute;
    cursor: pointer;
    bottom: 15%;
    right: 41.5%;
    font-family: 'Cambay';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
    color: #6EA0FF;
  }
  
  .modal-body {
    overflow: auto;
  }
  
  .modal-content {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 10rem;
    padding-top: -5rem;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
  }
  
  @keyframes appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slide-in {
    from {
      transform: translateY(-150px);
    }
    to {
      transform: translateY(0);
    }
  }
.Children {
  display: flex;
  max-width: 428px;
  margin: auto;
  min-height: 100vh;
	background: url(/public/images/child.png) right top no-repeat;
  }
  .Childre {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
    width: 100%;
    top:16%;
}
.container-childrens {
  width: 100%;
}
.pick_list {
    position: absolute;
    top: 2.5%;
    left: 7%;
    width: 252px;
    height: 29px;
    font-family: 'Cambay';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 33px;
    color: #FFFFFF;
    z-index: 1000;
  }
  .Union {
    position: absolute;
    top: 5.5%;
    left: 1%;
    z-index: 1000;
  }
  .donate_button_child {
    position: absolute;
    background: #FFFFFF;
    font-family: 'Roboto';
    border: 1px solid #558CF3;
    border-radius: 8px;
    left: 65%;
    width: 120px;
    height: 36px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 36px;
    text-align:center;
    color: #558CF3;
    text-decoration: none;
    align-items: center;
    justify-content: center;
}
@media screen and (max-height: 700px) {
  .Childre {
    top:20%;
}
  }
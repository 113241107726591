.category-item {
    position: relative;
    box-sizing: border-box;
    text-decoration: none;
    color: inherit;
    display: block;
    padding: 9px 9px;
}

.category-item img {
    max-width: 100%;
    background: linear-gradient(359.52deg, rgba(0, 0, 0, 0.64) 25.86%, rgba(0, 0, 0, 0.4096) 52.2%, rgba(0, 0, 0, 0) 89.08%);
    border-radius: 8px;
}

.category-item__title {
    margin-left: 5%;
    position: absolute;
    text-align: left;
    font-weight: bold;
    top: 70%;
    z-index: 100;
    font-family: 'Cambay', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 23px;
/* identical to box height */
    color: #FFFFFF;
}


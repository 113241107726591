
.onboard {
	display: flex;
	max-width: 375px;
	min-height: auto;
	padding: 0 19px;
	margin: auto;
	min-height: 100vh;
	background: #1A1A1A;
	align-items: center;
}
.wrapper {
	height: 300px;
	margin: 100px auto 400px;
	position: relative;
	width: 304px;
	z-index: 10;

}

.slide-title {
	position: absolute;
	bottom: 118%;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: #FFFFFF;
	z-index: 1000;
	visibility: visible;
}

.slide-title1 {
	position: absolute;
	bottom: -200%;
	font-family: 'Cambay';
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 20px;
	color: #FFFFFF;
	left: 50%;
	opacity: 0.2;
}

.slider {
	height: inherit;
	overflow: visible;
	position: relative;
	width: inherit;
	top: 55%;
	margin: auto;
	-webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .5);
	-moz-box-shadow: 0 0 20px rgba(0, 0, 0, .5);
	-o-box-shadow: 0 0 20px rgba(0, 0, 0, .5);
	box-shadow: 0 0 20px rgba(0, 0, 0, .5);
}

.slides {
	height: inherit;
	opacity: 0;
	position: absolute;
	width: inherit;
	z-index: 0;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
	-webkit-transition: transform ease-in-out .5s, opacity ease-in-out .5s;
	-moz-transition: transform ease-in-out .5s, opacity ease-in-out .5s;
	-o-transition: transform ease-in-out .5s, opacity ease-in-out .5s;
	transition: transform ease-in-out .5s, opacity ease-in-out .5s;
}

#slide1:checked ~ .slider > .slide1,
#slide2:checked ~ .slider > .slide2,
#slide3:checked ~ .slider > .slide3 {
	opacity: 1;
	z-index: 1;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}

.slide1 { background-image: url("/public/images/onb1.png"); }
.slide2 { background-image: url("/public/images/onb2.png"); }
.slide3 { background-image: url("/public/images/onb3.png"); }

.wrapper > input {
	display: none;
}

.wrapper .controls {
	left: 33%;
	position: absolute;
	bottom: -70%;
}
.wrapper .controls-2 {
	left: -100%;
	width: 330px;
	display: inline;
	position: absolute;
	bottom: 1190%;
}
.wrapper .label2 {
	cursor: pointer;
	max-width: 428px;
	width: 107px;
	height: 4px;
	margin: 1px;
	position: relative;
	-webkit-border-radius: 1%;
	-moz-border-radius: 1%;
	-o-border-radius: 1%;
	border-radius: 1%;
	background: rgba(255, 255, 255, 0.1);
	-webkit-transition: background ease-in-out .1s;
	-moz-transition: background ease-in-out .1s;
	-o-transition: background ease-in-out .1s;
	transition: background ease-in-out .1s;

}

.wrapper label {
	cursor: pointer;
	display: inline-block;
	height: 8px;
	margin: 25px 12px 0 16px;
	position: relative;
	width: 8px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-o-border-radius: 50%;
	border-radius: 50%;
	background: rgba(255, 255, 255, 0.1);
	-webkit-transition: background ease-in-out .1s;
	-moz-transition: background ease-in-out .1s;
	-o-transition: background ease-in-out .1s;
	transition: background ease-in-out .1s;

}

.wrapper label:hover, 
#slide1:checked ~ .controls label:nth-of-type(1),
#slide2:checked ~ .controls label:nth-of-type(2),
#slide3:checked ~ .controls label:nth-of-type(3) {
	background: #4482F8;;
}

.wrapper label:after {
	content: "";
	display: block;
	height: 12px;
	left: -4px;
	position: absolute;
	top: -4px;
	width: 12px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-o-border-radius: 50%;
	border-radius: 50%;
}
.wrapper .label2:after {
	display: block;
	height: 12px;
	left: -4px;
	position: absolute;
	top: -4px;
	width: 12px;
}
.logo-onb {
    top: 2em;
    justify-content: flex-end;
    padding-left: 1.5rem;
    white-space: nowrap;
    position: absolute;
  }

  .help_button_onb {
	position: absolute;
	left: 5.07%;
	right: 5.07%;
	top: 89.16%;
	bottom: 4.93%;
	text-decoration: none;
	justify-content: center;
	align-items: center;
	height: 48px;
	background: #4482F8;
	border: 1px solid #558CF3;
	border-radius: 12px;
	z-index: 5;
	font-family: 'Cambay';
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 45px;
	text-align: center;
	color: #FFFFFF;
  }

  @media screen and (max-height: 768px) {
	.help_button_onb {
	  margin-top: 20%;
	}
  }
  @media screen and (max-height: 700px) {
	.help_button_onb {
	  margin-top: 30%;
	}
  }
  @media screen and (max-height: 650px) {
	.help_button_onb {
	  margin-top: 40%;
	}
  }
  @media screen and (max-height: 60px) {
	.help_button_onb {
	  margin-top: 50%;
	}
  }
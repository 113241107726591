* {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}
html {
height: 100%;
background: #1A1A1A;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  width: 100%;
  height: 100%;
  padding: 0 0;
  background: #1A1A1A;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}


.Home {
  display: flex;
  max-width: 428px;
  margin: auto;
  min-height: 100vh;
  background:
  url(/public/images/Ukrainian_graphics1.png) right bottom  repeat-y,
  url(/public/images/Woman_Urkaine1.png) right bottom no-repeat,
  url(/public/images/Logo-2.png) left 10% top 5% no-repeat;

}
  .title1 {
    position: absolute;
    width: 256px;
    height: 29px;
    left: 24px;
    top: 164px;
    font-family: 'Cambay';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
    color: #FFFFFF;
    text-align:left;
    z-index: 300;
  }
  .icon_heart {
    position: absolute;
    margin-left: 2px;
  }
  .title2 {
    position: absolute;
    width: 288px;
    height: 145px;
    left: 24px;
    top: 217px;
    font-family: 'Cambay';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
    color: #FFFFFF;
    text-align:left;
    z-index: 300;
  }
  .how_it_works {
    position: absolute;
    left: 35%;
    right: 35%;
    top: 84%;
    width: 111px;
    height: 29px;
    font-family: 'Cambay';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
    color: #4482F8;
    text-decoration: none;
    z-index: 5;
  }
  .help_button {
    position: absolute;
    left: 5.07%;
    right: 5.07%;
    top: 89.16%;
    bottom: 4.93%;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    height: 48px;
    background: #4482F8;
    border: 1px solid #558CF3;
    border-radius: 12px;
    z-index: 5;
    font-family: 'Cambay';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 45px;
    text-align: center;
    color: #FFFFFF;
  }
  .help_button:hover {
      box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
  }
  